import { useState, useEffect } from "react";
import {
  Divider,
  Box,
  Input,
  Stack,
  SkeletonText,
  InputGroup,
  InputLeftElement,
  Button,
  Popover,
  PopoverTrigger,
  Grid,
  GridItem,
  Card,
  Heading,
  Center,
  PopoverCloseButton,
  PopoverBody,
  PopoverContent,
  PopoverArrow,
  SimpleGrid,
  Container,
  Flex,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import ListItem from "./ListItem";
import "./List.css";
import axios from "axios";
import PouchDB from "pouchdb";

const db = new PouchDB("ls-database");
const todosCacheKey = `todos-${process.env.REACT_APP_USER}`;

const List = () => {
  const [todos, setTodos] = useState([]);
  const [operating, setOperating] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [input, setInput] = useState("");
  const [predefinedTags, setPredefinedTags] = useState(["today", "daily"]);
  const [allTags, setAllTags] = useState([]);

  async function handleSubmit(event) {
    event.preventDefault();
    setOperating(true);
    await axios.post(process.env.REACT_APP_API_URI + "api/v1/todo", {
      title: input,
      tag: event.target.id,
      description: "",
      done: false,
      user: process.env.REACT_APP_USER,
    });
    await fetchTodos();
    setInput("");
    setOperating(false);
  }
  const handleOnChange = (event) => {
    const { value } = event.target;
    setInput(value);
  };

  const processTodosData = (data) => {
    // get all tags
    const tags = data.map((todo) => todo.tag);
    const customTags = [...new Set(tags)].filter(
      (tag) => !predefinedTags.includes(tag)
    );
    // put predefined tags first
    const sortedTags = [...predefinedTags, ...customTags];
    setAllTags(sortedTags);
  };

  const fetchTodos = async (firstLoad) => {
    if (firstLoad) {
      try {
        const { todos } = await db.get(todosCacheKey);
        if (todos) {
          setTodos(todos);
          console.log("cached data", todos);
          processTodosData(todos);
          setFirstLoad(false);
        }
      } catch (err) {
        console.log("no cached data");
        await db.put({
          _id: todosCacheKey,

          todos: [],
        });
      }
    }
    const { data } = await axios.get(
      process.env.REACT_APP_API_URI +
        `api/v1/todo/${process.env.REACT_APP_USER}`
    );
    console.log("fetched data", data);
    processTodosData(data);
    setTodos(data);
    if (firstLoad) {
      setFirstLoad(false);
    }

    //cache todos with updated revision
    const { _rev } = await db.get(todosCacheKey);
    await db.put({
      _id: todosCacheKey,
      _rev,
      todos: data,
    });
  };

  const onToggle = async (_id, done) => {
    setOperating(true);
    await axios.post(
      process.env.REACT_APP_API_URI + "api/v1/todo/" + _id + "/toggle",
      { done: !done }
    );
    await fetchTodos();
    setOperating(false);
  };
  const onRemove = async (_id) => {
    setOperating(true);
    await axios.delete(process.env.REACT_APP_API_URI + "api/v1/todo/" + _id);
    await fetchTodos();
    setOperating(false);
  };
  const moveToToday = async (_id, currentTag) => {
    setOperating(true);
    await axios.put(process.env.REACT_APP_API_URI + "api/v1/todo/" + _id, {
      tag: "today",
      previousTag: currentTag,
    });
    await fetchTodos();
    setOperating(false);
  };
  const moveBackToPrevious = async (_id, previousTag) => {
    setOperating(true);
    await axios.put(process.env.REACT_APP_API_URI + "api/v1/todo/" + _id, {
      tag: previousTag,
      previousTag: null,
    });
    await fetchTodos();
    setOperating(false);
  };

  const copyAllDailyToToday = async () => {
    setOperating(true);
    await axios.post(
      process.env.REACT_APP_API_URI +
        "api/v1/todo/" +
        process.env.REACT_APP_USER +
        "/copyAll",
      {
        fromTag: "daily",
        toTag: "today",
      }
    );
    await fetchTodos();
    setOperating(false);
  };

  const deleteAll = async (tag) => {
    setOperating(true);
    await axios.delete(
      `${process.env.REACT_APP_API_URI}api/v1/todo/${process.env.REACT_APP_USER}/${tag}`
    );
    await fetchTodos();
    setOperating(false);
  };

  useEffect(() => {
    setFirstLoad(true);
    fetchTodos(true);
  }, []);

  const renderTag = (tag) => {
    return (
      <WrapItem>
        <Card minW={"100%"} padding={5}>
          <Heading size="md">
            <Center>{tag}</Center>
          </Heading>
          <Box
            overflowY="auto"
            className="list-box"
            maxH={"20em"}
            minH={"20em"}
            css={{
              "&::-webkit-scrollbar": {
                width: "2px",
              },
              "&::-webkit-scrollbar-track": {
                width: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#D3D3D3",
                borderRadius: "24px",
              },
            }}
          >
            {todos.map((todo) => {
              if (todo.tag == tag && !todo.isPlaceholder)
                return ListItem(
                  todo,
                  onToggle,
                  onRemove,
                  moveToToday,
                  moveBackToPrevious
                );
            })}
          </Box>

          {"today" == "today" ? (
            <Popover>
              <PopoverTrigger>
                <Button colorScheme="red" variant="link" margin={2.5}>
                  Clear All
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  Are you sure you want to remove all todos from {tag}?
                  <Button
                    colorScheme="red"
                    variant="link"
                    margin={2.5}
                    onClick={() => deleteAll(tag)}
                  >
                    Yes, delete them!
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : null}

          {tag == "daily" ? (
            <Button
              colorScheme="blue"
              variant="link"
              margin={2.5}
              onClick={copyAllDailyToToday}
            >
              Copy All to Today's
            </Button>
          ) : null}
          <Divider />
          <form onSubmit={handleSubmit} id={tag}>
            <div>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<EditIcon color="gray.300" />}
                />
                <Input
                  placeholder="add a new item"
                  type="text"
                  value={input}
                  onChange={handleOnChange}
                />
              </InputGroup>
            </div>
          </form>
        </Card>
      </WrapItem>
    );
  };

  if (firstLoad) {
    return (
      <Stack className="skeleton-stack">
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
      </Stack>
    );
  }
  return (
    <SimpleGrid minChildWidth="22em" spacing="20px">
      {allTags?.map((tag) => renderTag(tag))}
    </SimpleGrid>
  );
};

export default List;

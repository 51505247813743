import { ArrowUpIcon, CloseIcon } from "@chakra-ui/icons";
import { Text, Card, Box, IconButton, Tag, Tooltip } from "@chakra-ui/react";

const ListItem = (
  todo,
  onToggle,
  onRemove,
  moveToToday,
  moveBackToPrevious
) => {
  const { _id, title, done, tag, previousTag } = todo;
  return (
    <Card className="list-item" key={todo._id}>
      <Box>
        {!["today", "daily"].includes(tag) ? (
          <IconButton
            aria-label="Move to Today"
            icon={<ArrowUpIcon />}
            size="xs"
            onClick={() => moveToToday(_id, tag)}
          />
        ) : (
          <></>
        )}
        <IconButton
          size="xs"
          aria-label="Delete the item"
          icon={<CloseIcon />}
          onClick={() => onRemove(_id)}
        />
        <Text
          className="title-text"
          as={done ? "s" : "bold"}
          onClick={() => onToggle(_id, done)}
        >
          {title}
        </Text>
        {previousTag ? (
          <Tooltip label={`Click to move back to ${previousTag}`}>
            <Tag
              onClick={() => moveBackToPrevious(_id, previousTag)}
              size={"sm"}
              m={"1"}
            >
              {previousTag}
            </Tag>
          </Tooltip>
        ) : (
          <></>
        )}
      </Box>
    </Card>
  );
};

export default ListItem;

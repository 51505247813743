import axios from "axios";
import Header from "../components/Header";
import "./Home.css";

const About = () => {
  return (
    <div>
      <Header />
      <h1>This is About Page</h1>
    </div>
  );
};

export default About;

import ListPage from "./Todo/ListPage";
const Body = () => {
  return (
    <div className="body">
      <ListPage />
    </div>
  );
};

export default Body;

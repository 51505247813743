import {
  Avatar,
  Button,
  Flex,
  Spacer,
  Tag,
  TagLabel,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { MoonIcon, SunIcon, HamburgerIcon, AddIcon } from "@chakra-ui/icons";
import DrawerPanel from "./DrawerPanel";
import NewTagModel from "./Todo/NewTagModal";
const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Flex>
      <Tag size="lg" colorScheme="green" borderRadius="full">
        <Avatar
          src="https://www.dictionary.com/e/wp-content/uploads/2018/09/monkey-emoji.jpeg"
          size="xs"
          name={process.env.REACT_APP_USER}
          ml={-1}
          mr={2}
        />
        <TagLabel className="tag-label">{process.env.REACT_APP_USER}</TagLabel>
      </Tag>
      <Spacer />

      <Button variant="ghost">
        <NewTagModel />
      </Button>
      <Button variant="ghost" onClick={toggleColorMode}>
        {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
      </Button>
      <Button variant="ghost" onClick={onOpen}>
        <HamburgerIcon />
        <DrawerPanel isOpen={isOpen} onClose={onClose} />
      </Button>
    </Flex>
  );
};

export default Header;

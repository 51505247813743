import List from "./List";
const ListPage = () => {
  return (
    <>
      <List />
    </>
  );
};

export default ListPage;

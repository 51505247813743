import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import React from "react";

function NewTagModel() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [tagName, setTagName] = React.useState("");
  async function addSubmit() {
    //post call to add tag
    await axios.post(process.env.REACT_APP_API_URI + "api/v1/todo", {
      title: tagName,
      tag: tagName,
      description: tagName,
      done: false,
      user: process.env.REACT_APP_USER,
      isPlaceholder: true,
    });
    onClose();
    window.location.reload();
  }
  const handleOnChange = (event) => {
    const { value } = event.target;
    setTagName(value);
  };
  return (
    <>
      <AddIcon onClick={onOpen}></AddIcon>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a new list tag</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <Input
                onChange={handleOnChange}
                value={tagName}
                ref={initialRef}
                placeholder="shopping list"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={addSubmit}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NewTagModel;
